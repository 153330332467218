import { useRouter } from 'apis/history';
import { Seo, Text } from 'components';
import Header from 'components/Header';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import Footer from 'components/Footer';
import Team from '../../assets/images/teamMembers/Team.png';
import Quote from '../../assets/icons/symbol.png';
import Overlay from 'pages/start/quiz/components/Overlay';

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;
  align-items: center;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${mobile} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  margin: 0 1.5rem;
  white-space: nowrap;
`;

const Story = () => {
  const [show, setShow] = useState<boolean>(false);

  const { isMobile } = useQuery();

  const { goToContact, goToStory, goToReviews, goToFAQ, goToStarterGuide } =
    useRouter();

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        <StyledContainer onClick={() => goToStory()}>
          <Text type="body500">Our Story</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToReviews()}>
          <Text type="body500">Reviews</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToFAQ()}>
          <Text type="body500">FAQ</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStarterGuide()}>
          <Text type="body500">Starter Guide</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToContact()}>
          <Text type="body500">Contact Us</Text>
        </StyledContainer>
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isMobile && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );

  return (
    <>
      <Seo
        title="Our Story"
        description="See how it all started"
        name="Our Story"
      />
      <MainContainer className={show ? 'active-menu' : ''}>
        <Header
          logoVariant="left"
          sticky={true}
          RightComponent={renderRightComponent()}
          color="light0"
        />
      </MainContainer>

      {show && (
        <Overlay
          zIndex={1}
          navLinks={[
            { title: 'Our Story', url: 'https://effecto.app/story' },
            { title: 'Reviews', url: 'https://effecto.app/reviews' },
            { title: 'FAQ', url: 'https://effecto.app/faq' },
            { title: 'Starter Guide', url: 'https://effecto.app/single' },
            { title: 'Contact Us', url: 'https://effecto.app/contact' },
          ]}
          socialLinks={[
            { svgId: 'f-reddit', url: 'https://www.reddit.com/r/EffectoApp/' },
            { svgId: 'f-tw', url: 'https://twitter.com/EffectoApp' },
            { svgId: 'f-fb', url: 'https://www.facebook.com/TheEffectoApp' },
            {
              svgId: 'f-instagram',
              url: 'https://www.instagram.com/effecto.app/',
            },
          ]}
        />
      )}
      <Container>
        <ImageContainer>
          <StyledImage src={Team} />
        </ImageContainer>
        <TextContainer>
          <TextWrap>
            <Title type={isMobile ? 'countS' : 'h13Rem400'}>
              We care about <strong>your health</strong>
            </Title>
            <SubTitle>
              We believe that everyone should have access to a comprehensive
              tool that helps them manage chronic conditions and improve their
              overall health based on simple, user-friendly technology and
              customized tracking.
            </SubTitle>
          </TextWrap>
        </TextContainer>
      </Container>
      <StartedContainer>
        <StartedWrapper>
          <Entry>
            <EntryTitle>How it started</EntryTitle>
            <EntryHeading>From the beginning...</EntryHeading>
            <EntryText>
              Our journey began back in 2004 when we first met. Jokubas and I
              (Kazys, hi!) were good friends from a pretty early age. During our
              years at high school, we were especially interested in a healthy
              lifestyle and medicine; you could have called us some sort of
              geeks at the time, always into books and magazines. It’s no
              surprise that this interest quickly grew into friendly challenges
              between the two of us where we’d monitor ourselves and how
              different factors influenced response to certain events: our
              productivity, decision-making, mood, even illnesses – you name it.
              It was a pure interest turned into a game for us.
            </EntryText>
            <EntryText>
              The amount of tools and time spent tracking those factors was
              crazy, starting from collecting notes to 50-page excel sheets.
              Although things were going well at first, the tracking wasn’t
              consistent enough, we’d forget to log something or remind each
              other about a specific step, making us realize that we basically
              needed a collective platform. By monitoring each other and giving
              constant advice on self-improvement healthwise, we came to the
              conclusion that simple advice just wasn’t enough. The best and
              quickest way to break harmful habits and improve overall
              well-being was a clear self-analysis and detailed statistics.
            </EntryText>
            <EntryHeading>Exploring the potential</EntryHeading>
            <EntryText>
              The notion continued to grow among us until we both began our
              medical studies in 2016 and noticed there was a massive demand for
              such a collective platform since we found out that lots of people
              used similar health tracking techniques to no avail. Seeing some
              of our loved ones suffer from chronic diseases and being fanatics
              of self-improvement ourselves, our passion for research never
              stopped. With a shared enthusiasm for healthcare, we joined
              several healthcare organizations and attended numerous
              conferences, observing the health and wellness sector from all
              angles. Yeah, it was a little tedious, but in the end – totally
              worth it.
            </EntryText>
            <EntryText>
              That&apos;s when we discovered that we, like everyone else, are
              always striving to reach our full potential, either physically or
              mentally, we might just struggle a bit without the right tools.
            </EntryText>
            <EntryText>
              Everyone does things that irritate them, reduce their energy,
              productivity, or otherwise affect their overall well-being. So we
              started thinking:
            </EntryText>
            <StyledBlockquote>
              <BlockquoteText>
                There has to be a method to carefully learn about ourselves in
                order to improve, while also helping as many people as possible,
                whether it is chronic conditions or simple self-betterment.
              </BlockquoteText>
            </StyledBlockquote>

            <EntryText>
              Soon, we realized that it’s a common issue among tons of people.
              And we wanted a tool that would help everyone, no matter their
              background or condition. So we connected the dots, and the
              equation was pretty clear.
            </EntryText>
            <EntryText>
              We saw great potential in such an idea, an app that could be the
              cornerstone for health tracking and self-betterment. We knew it
              would take time and research, but we really believed in it and
              were ready to make the change. And that’s exactly what we did.
            </EntryText>
            <EntryHeading>Making a difference</EntryHeading>
            <EntryText>
              Taking matters into our own hands, we started our journey towards
              Effecto that same year. We already had a little entrepreneurial
              experience but still a long way ahead of us. We basically started
              from scratch, going back to our old routines of notes and excel
              sheets, consulting our friends, colleagues, and healthcare
              professionals. After we settled in and were more confident about
              our app, we wanted someone who could offer additional help
              business-wise, having expertise in analyzing user demand.
            </EntryText>
            <EntryText>
              As a result, our team added a third member Tomas – a talented
              marketing specialist and data analyst. And so the testing and
              development continued, carefully creating and studying various
              user cases so that our app would be useful to everyone: from
              completely healthy, physically active people to habit trackers and
              self-analyzers to those who might have chronic conditions to
              control.
            </EntryText>
          </Entry>
        </StartedWrapper>
      </StartedContainer>
      <ProudContainer>
        <ProudWrap>
          <ProudEntry>
            <ProudTitle>The outcome we&apos;re proud of</ProudTitle>
            <ProudText>
              What had started as a hobby and simple childish interest has
              finally transformed into a comprehensive instrument to help
              people. After many years of hard work and research, we’re proud to
              present you: <strong>Effecto</strong>.
            </ProudText>
            <ProudText>
              We aim to help those with chronic illnesses or those who simply
              want to enhance their health, and we’re sure that Effecto is just
              what you need to make that happen.
            </ProudText>
            <ProudText>
              Regain a sense of control of your health and begin your journey to
              a happier, healtier you.
            </ProudText>
          </ProudEntry>
        </ProudWrap>
      </ProudContainer>
      <Footer />
    </>
  );
};

export default Story;

const BlockquoteText = styled(Text)`
  letter-spacing: -0.024em;
  font-family: Inter;
  font-size: 1.25rem;
  @media ${mobile} {
    font-size: 1.125rem;
  }
`;

const StyledBlockquote = styled.blockquote`
  background: url(${Quote}) no-repeat 0 24px;
  background-size: 48px auto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 32px 0 22px;
  padding: 24px 0 12px 80px;
`;

const ProudText = styled(Text)`
  margin-bottom: 22px;
  color: #fff;
  font-family: Inter;
  font-weight: 300;
`;

const ProudTitle = styled(Text)`
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  font-family: Inter;
  @media ${mobile} {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }
`;

const ProudEntry = styled.div`
  margin: auto;
  max-width: 768px;
  line-height: 138%;
`;

const ProudWrap = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ProudContainer = styled.div`
  background: #1c1c28;
  color: #fff;
  padding-bottom: 72px;
  padding-top: 72px;
  @media ${mobile} {
    padding-bottom: 24px;
    padding-top: 46px;
  }
`;

const EntryText = styled(Text)`
  margin-bottom: 22px;
  font-family: Inter;
  font-weight: 300;
  color: #28293d;
`;

const EntryHeading = styled(Text)`
  color: #1c1c28;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  margin-top: 48px;
  @media ${mobile} {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 32px;
  }
`;

const EntryTitle = styled(Text)`
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  color: #222;
  font-family: Inter;
  @media ${mobile} {
    font-size: 1.75rem;
    margin-bottom: 24px;
  }
`;

const Entry = styled.div`
  line-height: 150%;
  margin: auto;
  max-width: 768px;
`;

const StartedWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StartedContainer = styled.div`
  padding-bottom: 42px;
  padding-top: 42px;
`;

const Title = styled(Text)`
  color: #1c1c28;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 24px;
`;

const SubTitle = styled(Text)`
  font-size: 1.125rem;
  font-family: Inter;
  color: #1c1c28;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.024em;
  line-height: 150%;
  @media ${mobile} {
    font-size: 1rem;
  }
`;

const TextWrap = styled.div`
  width: 46%;
  @media ${mobile} {
    width: unset;
  }
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledImage = styled.img`
  display: block;
  margin-left: auto;
  max-height: 100%;
  min-width: 768px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  @media ${mobile} {
    min-width: unset;
  }
`;

const ImageContainer = styled.div`
  bottom: 0;
  padding-bottom: 24px;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  @media ${mobile} {
    margin: 0;
    position: static;
    width: auto;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 78px;
  min-height: 600px;
  position: relative;
  @media ${mobile} {
    display: block;
  }
`;
